<script setup>
import userStore from '@/store/module/user'
import system from "@/store/module/system.js";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {onMounted} from "vue";
const i18n = useI18n()
xb.$t = i18n.t
xb.i18n = i18n
xb['$user'] = userStore()
xb['$sys'] = system()
xb.$sys.getDomain()

const router = useRouter()
xb.$on('logOut',e=>{
  router.push({path:'/'})
})
router.afterEach((to,from)=>{
  if(to.matched.length === 0){
    window.location.hash = from.hash
    return false
  }
  if(xb.$user.isLogin)xb.$user.getUserInfo()

})
var getParams = () => {
  let url = location.hash.split('?')[1]
  if (url && url.indexOf('token') != -1) {
    let res = {}
    url.split('&').forEach(item => {
      res[item.split('=')[0]] = item.split('=')[1]
    })
    return res
  }
  return url
}
var temp = getParams()
if(xb.$user.isLogin && !temp?.token){
  xb.$user.getUserInfo()
  xb.$user.scheduledTasksForSeller(true)
  xb.$api.queryUserWallet().then(res => {
    if(res.length>0){
      xb.$user.wallet = res
      xb.$user.activeWallet = res[0]
    }
  })
  xb.$api.getPayChannel().then(res => {
    xb.$user.PayChannel = res
  })
  xb.$api.getHashMapByKey({ key: 'pay_password_switch' }).then(res => {
    xb.$user.payPasswordSwitch = res?.value == 0
  })
  xb.$api.getHashMapByKey({ key: 'wallet_address_modification' }).then(res => {
    xb.$user.editAddress = !(res?.value == 0)
  })
}else{


  if (temp) {
    xb.$user.token = temp.token
    xb.$user.isLogin = true
    setTimeout(()=>{
      window.location.href = window.location.origin+'#/entrust'
      window.location.reload()
    },1000)

  }else{
    xb.$emit('noLogin')
  }

}
onMounted(async ()=>{
  await xb.$user.getShopLevel()
  xb.$sys.setLang(xb.$sys.lang)
})
</script>
<script>
export default {
  async created() {
    let a = await xb.$user.getShopLevel()
  }
}
</script>
<template>
  <div class="versioon">
    v1.2.3
  </div>
  <router-view/>
</template>

<style>
.versioon{
  position: fixed;
  bottom:10px;
  right:10px
}
#app {
  text-transform:none !important;
  background-color: #f4f4f4;
  height: 100%;
  width: 100%;
  min-width: 1512px;
}
@font-face {
  font-family: 'D-DIN-PRO';
  src: url('@/assets/fonts/D-DIN-PRO-700-Bold.ttf');
}
html,body {
  height: 100%;
  width: 100%;
  min-width: 1512px;
  background-color: #f4f4f4;
}
.el-button--primary{
  background-color: #222;
  border: none;
}
</style>
