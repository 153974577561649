import { createApp } from 'vue'
import ElementPlus, {ElLoading} from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from "./router/index.js";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { ElMessage,ElMessageBox } from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import i18n from '@/i18n/index.js'
// 引入持久化插件
import piniaPluginPersist from 'pinia-plugin-persist'
import svgIcon from "@/components/SvgIcon/index.vue";
import 'virtual:svg-icons-register'
import commonImage from "@/components/commonImage/index.vue";
import moment from "moment"
import pwdInput from '@/utils/pwdConfirm.js'
import logOut from "@/utils/logOut.js";
xb.message = ElMessage
window.console.log = ()=>{

}
xb.messageBox  = ElMessageBox
if(!localStorage.getItem('lang')){
    localStorage.setItem('lang','en')
}
// 请求挂载
if(import.meta.env.MODE == 'buyer'){
    xb.$api = import.meta.globEager('@/buyer/api/api.js')['/src/buyer/api/api.js'].default
}else{
    xb.$api = import.meta.globEager('@/seller/api/api.js')['/src/seller/api/api.js'].default
}
xb.$loading = {
    show: function (options = { background: 'rgba(0,0,0,.4)' }) {

        this.loding = ElLoading.service(options)
    },
    hide: function () {
        if (!this.loding) return
        this.loding.close()
    },
    loding: ''
}
xb.$logOut = logOut
xb.$pwd = pwdInput
xb.$copy = (data) => {
    var input = document.createElement("textarea"); // js创建一个input输入框
    input.value = data;  // 将需要复制的文本赋值到创建的input输入框中，this.ruleForm.url这个是我要复制的内容
    document.body.appendChild(input);    // 将输入框暂时创建到实例里面
    input.select();   // 选中输入框中的内容
    document.execCommand("Copy");   // 执行复制操作
    document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
    ElMessage({
        message: 'Cpoyed',
        type: 'success',
    })
}
if(import.meta.env.PROD){
    window.console.log  = function (){}
}
const store = createPinia()
// 使用该插件
store.use(piniaPluginPersist)
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.config.globalProperties.$moment = moment;
app.config.globalProperties.xb=xb
// todo 届时加入国际化
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(store)
app.use(router)
app.component('svg-icon', svgIcon)
app.component('shop-img', commonImage)
app.use(i18n)
app.mount('#app')
